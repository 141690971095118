<template>
	<div class="home">
		<div class="left" v-loading="loading1">
			<div class="tree">
				<el-tree :expand-on-click-node="false" :data="tree" class="filter-tree" :props="defaultProps" :default-expanded-keys="[1]"
					:default-checked-keys="[1]" :highlight-current="true" @node-click="nodeClick" ref="tree">
					<span class="my-custom" slot-scope="{node,data}" style="width: 100%;height: 100%;">
						<!-- <img :src="data.path" style="margin-right: 10px;" /> -->

						<span style="width: 100%;height: 100%;line-height: 32px;">{{data.grid_name}}</span>
					</span>
				</el-tree>
			</div>
		</div>
		<div class="right">
			<div class="transformer">
				<div class="clearfix electric-select">
					<el-form :inline="true">
						<el-form-item label="人员名：">
							<el-input class="shuru" v-model="searchName"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="chaxun()">
								<i class="el-icon-search"></i>
								查询
							</el-button>
							<el-button type="primary" @click="dialogTableVisible = true" v-if="newbutton"><i
									class="el-icon-plus"></i> 新增</el-button>
						</el-form-item>
					</el-form>

				</div>
				<div v-loading="loading2">
					<el-table :data="tableData" stripe style="width: 100%;margin-top: 20px;">
						<el-table-column prop="regionname" label="社区" height show-overflow-tooltip align="center">
						</el-table-column>
						<el-table-column prop="name" label="人员" height show-overflow-tooltip align="center">
						</el-table-column>
						<el-table-column prop="sex" label="性别" width="100" height show-overflow-tooltip align="center">
						</el-table-column>
						<el-table-column prop="phone" label="手机号" width="200" height show-overflow-tooltip align="center">
						</el-table-column>
						<el-table-column label="操作" width="200" height show-overflow-tooltip align="center">
							<template slot-scope="scope">
								<el-button @click="xiugai(scope.row)" type="primary">修改</el-button>
								<el-button @click="shanchu(scope.row)" type="primary">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="block">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
						</el-pagination>
					</div>
				</div>
				<el-dialog title="新增人员" width='30%' :visible.sync="dialogTableVisible" :before-close="handleClose"
					:close-on-click-modal="false">
					<el-form ref="form" :model="form" label-width="100px">
						<el-form-item label="人员姓名">
							<el-input v-model="form.name"></el-input>
						</el-form-item>
						<el-form-item label="人员性别">
							<el-select style="width: 100% !important;" v-model="form.sex" placeholder="请选择">
								<el-option label="男" value="男">
								</el-option>
								<el-option label="女" value="女">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="人员手机号">
							<el-input v-model="form.phone"></el-input>
						</el-form-item>
						<el-form-item label="人员照片" class="personupload">
							<el-upload ref="upload" :class="{disabled: uploadDisabled}" action="" accept="image/png, image/jpeg, image/.jpg"
								list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
								:on-change="handleChange" :file-list="fileList" :auto-upload="false">
								<i class="el-icon-plus"></i>
							</el-upload>
							<el-dialog title="图片预览" append-to-body :visible.sync="dialogVisible">
								<img width="100%" :src="dialogImageUrl" alt="">
							</el-dialog>
							<el-input v-show="false" v-model="form.photo"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmit">立即创建</el-button>
							<el-button @click="close">取消</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
				<el-dialog title="修改人员信息" width='30%' :visible.sync="xiugaiVisible" :before-close="handleClose2"
					:close-on-click-modal="false">
					<el-form ref="form2" :model="form2" label-width="100px">
						<el-form-item label="人员姓名">
							<el-input v-model="form2.xingming"></el-input>
						</el-form-item>
						<el-form-item label="人员性别">
							<el-select style="width: 100% !important;" v-model="form2.xingbie" placeholder="请选择">
								<el-option label="男" value="男">
								</el-option>
								<el-option label="女" value="女">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="人员手机号">
							<el-input v-model="form2.dianhua"></el-input>
						</el-form-item>
						<el-form-item label="人员照片" class="personupload">
							<el-upload ref="upload1" :class="{ disabled: uploadDisabled }" action="" accept="image/png, image/jpeg, image/.jpg"
								list-type="picture-card" :on-preview="handlePictureCardPreview1" :on-remove="handleRemove1"
								:on-change="handleChange1" :file-list="fileList1" :auto-upload="false">
								<i class="el-icon-plus"></i>
							</el-upload>
							<el-dialog title="图片预览" append-to-body :visible.sync="dialogVisible1">
								<img width="100%" :src="dialogImageUrl1" alt="">
							</el-dialog>
							<el-input v-show="false" v-model="form2.photo"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmit2">修改</el-button>
							<el-button @click="close2">取消</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getwanggelist,
		addGridMan,
		updateGridMan,
		deleteGridMan,
		getGridManlist
	} from '@/api/wangge'
	export default {
		data() {
			return {
				time: new Date(),
				treeId: "",
				searchName: '',
				search: '',
				dialogTableVisible: false,
				tableData: [],
				currentPage: 1,
				fenye: {
					pagesizes: [10, 20, 30, 40],
					pagesize: 10,
					totalnum: 0
				},
				form: {
					name: '',
					sex: '男',
					phone: '',
					photo: ''
				},
				form2: {
					xingming: '',
					xingbie: '',
					dianhua: '',
					photo: ''
				},
				danyuan1: '',
				ceng1: '',
				fangjian1: '',
				danyuan2: '',
				ceng2: '',
				fangjian2: '',
				defaultProps: {
					children: 'regionlist',
					label: 'grid_name'
				},
				treeSelectId: "",
				tree: [],
				newbutton: false,
				regioncode: '',
				buildid: '',
				levelcode: '',
				levelname: '',
				xiugaiVisible: false,
				loading1: true,
				loading2: true,
				dialogImageUrl: '',
				dialogVisible: false,
				fileList: [],
				uploadDisabled: false,
				fileList1: [],
				dialogImageUrl1: '',
				dialogVisible1: false,
				
			}
		},
		watch: {
			radio(val, oldVal) {
				this.danyuan1 = '';
				this.ceng1 = '';
				this.fangjian1 = '';
				this.danyuan2 = '';
				this.ceng2 = '';
				this.fangjian2 = '';
			}
		},
		mounted() {
			this.getList();
			this.getTree();
		},
		methods: {
			//列表图 文件状态改变时的钩子
			handleChange(file, fileList) {
				if (fileList.length >= 1) {
					this.uploadDisabled = true;
				}else{
					this.uploadDisabled = false;
				}
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 2MB!');
					return false;
				}

				let formData = new FormData();
				formData.append('upfilelist', file.raw);
				this.$axios.post('/fileutil/UpLoadFileList', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					if (res.data.result == 200) {
						console.log(res.data.detail)
						file.upId = res.data.detail;
						console.log(fileList)
						let ids = [];
						fileList.map(f => {
							ids.push(f.upId)
						})
						this.form.photo = ids.length ? ids.join(',') : '';
					} else {
						this.$message.error(res.data.description);
					}
				})

			},
			//列表图 文件列表移除文件时的钩子
			handleRemove(file, fileList) {
				if (fileList.length >= 1) {
					this.uploadDisabled = true;
				}else{
					this.uploadDisabled = false;
				}
				let ids = [];
				fileList.map(f => {
					ids.push(f.upId)
				})
				this.form.photo = ids.length ? ids.join(',') : '';
			},
			//图片预览
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			

			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			getTree() {
				this.loading1 = true;
				getwanggelist({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId(),
				}).then((res) => {
					this.loading1 = false;
					if (res.result == '200') {
						this.tree = res.detail.levelCode;
						// console.log(this.tree);
						for (var a = 0; a < this.tree.length; a++) {
							this.tree[a].grid_name = this.tree[a].name.split('(')[0] + '(' + this.tree[a].mannum +
								')';
							this.tree[a].ji = 1;
							this.tree[a].num = a;
							for (var i = 0; i < this.tree[a].wanggelist.length; i++) {
								this.tree[a].wanggelist[i].name = this.tree[a].name.split('(')[0];
								this.tree[a].wanggelist[i].ji = 2;
								this.tree[a].wanggelist[i].num = i;
								this.tree[a].wanggelist[i].prevnum = a;
							}
						}
						console.log(this.tree);
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: res.description
						});
					}
				})
			},
			handleClose: function(done) {
				this.form = {
					name: '',
					sex: '男',
					phone: '',
					photo: ''
				};
				done();
			},
			handleClose2: function(done) {
				this.form2 = {
					xingming: '',
					xingbie: '',
					dianhua: '',
					photo: ''
				};
				done();
			},
			onSubmit() {
				let that = this;
				console.log(this.form.photo)
				if (that.form.name == '') {
					that.$message.error('请填写网格员姓名');
					return;
				}
				if (that.form.phone.length != 11) {
					that.$message.error('请填写正确的网格员手机号');
					return;
				}
				
				if(!that.form.photo){
					that.$message.error('请上传照片');
					return;
				}
				addGridMan({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					levelcode: that.levelcode,
					name: that.form.name,
					phone: that.form.phone,
					sex: that.form.sex,
					photo: that.form.photo
				}).then((res) => {
					if (res.result == '200') {
						that.$message({
							showClose: true,
							message: '添加成功',
							type: 'success'
						});
						that.form = {
							name: '',
							sex: '男',
							phone: '',
							photo: ''
						}
						this.$refs.upload.clearFiles();
						this.uploadDisabled = false;
						that.searchName = '';
						that.search = '';
						that.getTree();
						that.getList();
						that.dialogTableVisible = false;
					} else {
						that.$message({
							showClose: true,
							type: 'error',
							message: res.description
						});
					}
				})
			},
			close() {
				var that = this;
				that.dialogTableVisible = false;
				that.form = {
					name: '',
					sex: '男',
					phone: '',
					photo: ''
				}
				this.$refs.upload.clearFiles();
				this.uploadDisabled = false;
			},
			close2() {
				var that = this;
				that.xiugaiVisible = false;
				that.form2 = {
					xingming: '',
					xingbie: '',
					dianhua: '',
					photo: ''
				}
				this.$refs.upload1.clearFiles();
				this.uploadDisabled = false;
			},
			//修改
			//列表图 文件状态改变时的钩子
			handleChange1(file, fileList) {
				if (fileList.length >= 1) {
					this.uploadDisabled = true;
				}else{
					this.uploadDisabled = false;
				}
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 2MB!');
					return false;
				}

				let formData = new FormData();
				formData.append('upfilelist', file.raw);
				this.$axios.post('/fileutil/UpLoadFileList', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					if (res.data.result == 200) {
						console.log(res.data.detail)
						file.upId = res.data.detail;
						console.log(fileList)
						let ids = [];
						fileList.map(f => {
							ids.push(f.upId)
						})
						this.form2.photo = ids.length ? ids.join(',') : '';
					} else {
						this.$message.error(res.data.description);
					}
				})

			},
			//列表图 文件列表移除文件时的钩子
			handleRemove1(file, fileList) {
				if (fileList.length >= 1) {
					this.uploadDisabled = true;
				}else{
					this.uploadDisabled = false;
				}
				let ids = [];
				fileList.map(f => {
					ids.push(f.upId)
				})
				this.form2.photo = ids.length ? ids.join(',') : '';
			},
			//图片预览
			handlePictureCardPreview1(file) {
				this.dialogImageUrl1 = file.url;
				this.dialogVisible1 = true;
			},
			xiugai(a) {
				var that = this;
				// that.form2 = form;
				console.log(a);
				that.form2 = {
					xingming: a.name,
					xingbie: a.sex,
					dianhua: a.phone,
					manid: a.manid,
					photo: a.photo
				}
				if(a.photo && a.photopath){
					that.uploadDisabled = true;
					that.fileList1 = [{name : a.photo, url: a.photopath}];
				}else{
					that.uploadDisabled = false;
					that.fileList1 = [];
				}
				that.levelcode = a.levelcode;
				that.xiugaiVisible = true;
			},
			//修改
			onSubmit2() {
				var that = this;

				if (that.form2.xingming == '') {
					that.$message({
						showClose: true,
						message: '请填写网格员姓名',
						type: 'error'
					});
					return;
				}
				if (that.form2.dianhua.length != 11) {
					that.$message({
						showClose: true,
						message: '请填写正确的网格员手机号',
						type: 'error'
					});
					return;
				}

				if(!that.form2.photo){
					that.$message.error('请上传照片');
					return;
				}
				updateGridMan({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					levelcode: that.levelcode,
					manid: that.form2.manid,
					name: that.form2.xingming,
					phone: that.form2.dianhua,
					sex: that.form2.xingbie,
					photo: that.form2.photo
				}).then((res) => {
					if (res.result == '200') {
						that.$message({
							showClose: true,
							message: '修改成功',
							type: 'success'
						});
						that.form2 = {
							xingming: '',
							xingbie: '',
							dianhua: ''
						};
						that.searchName = '';
						that.search = '';
						that.getList();
						that.xiugaiVisible = false;
					} else {
						that.$message.error(res.description);
					}
				})
			},
			shanchu(a) {
				var that = this;
				that.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deleteGridMan({
						'x-access-token': that.GLOBAL.token(),
						userid: that.GLOBAL.adminId(),
						manid: a.manid
					}).then((res) => {
						if (res.result == '200') {
							that.$message({
								showClose: true,
								type: 'success',
								message: '删除成功!'
							});
							that.searchName = '';
							that.search = '';
							that.currentPage = 1;
							that.getTree();
							that.getList();
						} else {
							that.$message({
								showClose: true,
								type: 'error',
								message: res.description
							});
						}
					})

				}).catch(() => {

				});

			},
			chaxun() {
				this.currentPage = 1;
				this.search = this.searchName;
				this.getList();
			},
			getList() {
				var that = this;
				that.loading2 = true;
				getGridManlist({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					levelcode: that.levelcode,
					manid: '',
					page: that.currentPage,
					pagesize: that.fenye.pagesize,
					seachname: that.search,
					type: ''
				}).then((res) => {
					that.loading2 = false;
					if (res.result == '200') {
						that.tableData = res.detail.list;
						that.fenye.totalnum = res.detail.totalRow;
					} else {
						that.$message.error(res.description);
					}
				})
			},
			nodeClick(data) {
				console.log(data);
				var that = this;
				// that.tableData = [];
				that.searchName = '';
				that.search = '';
				that.levelcode = '';
				that.currentPage = 1;
				if (data.ji == 1) {
					that.newbutton = true;
					that.levelcode = data.levelcode;
					that.levelname = data.grid_name;
				} else {
					that.newbutton = false;
				}
				that.getList();
			}
		},

	}
</script>

<style lang="scss" scoped="scoped">
	.home {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		background: #fff;
		border: solid 1px #d7dde4;
		box-shadow: 0 0 3px rgba($color: #d7dde4, $alpha: 0.3);

		.left {
			width: 320px;
			height: 100%;
			overflow: auto;
			box-sizing: border-box;
			border: solid 1px #d7dde4;
			float: left;

			.search {
				width: 100%;
				box-sizing: border-box;
				padding: 16px;
				border-bottom: solid 1px #d7dde4;
			}

			& /deep/ .el-tree {
				.el-tree-node__content {
					height: 32px;
				}

				.el-icon-caret-right {
					width: 14px;
					text-align: center;
					padding-left: 16px;
					padding-right: 8px;
				}

				.el-icon-caret-right:not(.expanded):before {
					content: "+";
					font-size: 18px;
					font-weight: bold;
					transform: none;
					color: #848694;
				}

				.el-tree-node__expand-icon.expanded {
					transform: rotate(0deg);
				}

				.el-tree-node__expand-icon.expanded:before {
					content: "-";
					font-size: 18px;
					font-weight: bold;
					color: #848694;
				}

				.is-leaf {
					opacity: 0;
				}

				.my-custom {
					font-size: 14px;

					& * {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}

		.right {
			width: calc(100% - 320px);
			height: 100%;
			box-sizing: border-box;
			padding: 0 16px;
			float: left;

		}
	}

	.right>div {
		box-sizing: border-box;
		height: calc(100% - 32px);
		overflow-y: auto;
	}

	.loudong>p,
	.loudong>div,
	.loudong>div>div>div {
		padding: 10px 0;
	}

	.loudong .el-input {
		margin: 0 10px;
	}

	.personupload /deep/ .disabled .el-upload.el-upload--picture-card {
			display: none !important;
	}

	.personupload /deep/ .disabled .el-button--success.is-plain {
			display: none !important;
	}

</style>